import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Form from '../../../../components/Form';
import { channelAPI } from '../../../../api';
import { userManagement as $content } from '../../../../content';
import { clsm } from '../../../../utils';

const ResetPasswordRequestForm = ({ onSuccess }) => (
  <Form
    inputsData={{ email: {} }}
    onSuccess={onSuccess}
    submitHandler={channelAPI.sendResetPasswordRequest}
    submitText={$content.continue}
    title={$content.reset_password_page.title}
    footer={<Link to="/login">{$content.return_to_login}</Link>}
    notice={
      <div
        className={clsm([
          'w-full',
          'h-auto',
          'py-2',
          'px-4',
          'bg-amber-400',
          'rounded-xl',
          'text-center',
          'leading-6',
          '-translate-y-2'
        ])}
      >
        <span className={clsm(['text-black', 'font-medium'])}>
          Creating new accounts is disabled for this live demo. <br /> Please
        </span>
        &nbsp;
        <a
          href="https://aws.amazon.com/contact-us/sales-support/?pg=ivsprice"
          target="_blank"
          rel="noreferrer"
          className="text-orange-800"
        >
          book a demo walkthrough.
        </a>
      </div>
    }
  />
);

ResetPasswordRequestForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default ResetPasswordRequestForm;
